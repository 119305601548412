// @flow
import * as React from 'react'
import {
  BackToTop,
  Button,
  EN,
  Hero,
  HeroTitle,
  HeroBody,
  // Link,
  NL,
  Page,
  // Question,
  Section,
  Teammember,
  WallpaperSection,
} from '../components'

import Erwin from '../assets/team/erwin.jpg'
import Iskander from '../assets/team/iskander.jpg'
import Jacob from '../assets/team/jacob.jpg'
import Merijn from '../assets/team/merijn.jpg'
import Roeland from '../assets/team/roeland.jpg'
import Rohiet from '../assets/team/rohiet.jpg'
import Swier from '../assets/team/swier.jpg'

import Landscapebord from '../assets/Landscapebord_blue.png'
//import ErwinWerkend from '../assets/Erwin_werkend_green.png'
import LampTeam from '../assets/Lamp_team_green.png'

const BigNumber = ({ children }: { children: React.Node }) => (
  <span
    style={{
      fontSize: '7rem',
      lineHeight: '0.76em',
      position: 'absolute',
      left: '-.4rem',
      zIndex: '-1',
    }}
    className="text-transparentize"
  >
    {children}
  </span>
)

const TeamPhoto = ({ photo, name }: { photo: string, name: string }) => (
  <div className="col-auto px-2 py-2">
    <img
      src={photo}
      alt={name}
      className="rounded-circle img-fluid"
      style={{ maxWidth: '125px', border: '2px solid white' }}
    />
  </div>
)

export default function AboutUs(): React.Node {
  return (
    <Page
      title={{ nl: 'Over ons', en: 'About us' }}
      description={{
        nl: 'Bij Landscape zijn we gedreven om heldere, uitlegbare en op mensen gefocuste AI-oplossingen te bouwen',
        en: 'At Landscape we are driven to provide transparent, explainable and human-focused AI solutions',
      }}
      location={'/about-us'}
    >
      <Hero className="pb-0">
        {/* <div className="subtitle mb-3">
            <NL>Wie we zijn</NL>
            <EN>Who we are</EN>
          </div> */}
        <HeroTitle>
          <NL>Wij zijn jouw datapartner</NL>
          <EN>We are your data partner</EN>
        </HeroTitle>
        <HeroBody>
          <p>
            <NL>
              Wij zijn Landscape: een kleine organisatie met een groot hart voor data. Sinds 2014 helpen wij anderen als{' '}
              <span className="strong text-green">datapartner</span> met het op een waardevolle manier inzetten van data
              en AI in hun organisatie.{' '}
              <span className="strong text-green">Van algemeen idee tot en met geïmplementeerde oplossing</span>.
            </NL>
            <EN>
              We are Landscape: a small organisation with a big heart for data. Since 2014 we have been helping others
              as <span className="strong text-green">data partner</span> to implement data and AI solutions into their
              organisation in a valuable way.{' '}
              <span className="strong text-green">From general idea to implemented solution</span>.
            </EN>
          </p>
          <Button color="green" to="#vision" className="me-3">
            <NL>Onze visie</NL>
            <EN>Our vision</EN>
          </Button>
          <Button color="teal" to="#values" className="me-3">
            <NL>Onze waarden</NL>
            <EN>Our values</EN>
          </Button>
          <Button color="aqua" to="#team" className="me-3">
            <NL>Ons team</NL>
            <EN>Our team</EN>
          </Button>
          <Button color="blue" to="#attitude" className="me-3">
            <NL>Onze houding</NL>
            <EN>Our attitude</EN>
          </Button>
          <Button color="dark-blue" to={{ nl: '/diensten', en: '/services' }} className="me-3">
            <NL>Onze diensten</NL>
            <EN>Our services</EN>
          </Button>
        </HeroBody>
      </Hero>

      {/* <Section id="vision" gradient="green-teal"> */}
      <WallpaperSection
        //size="small"
        wallpaper={LampTeam}
        wallpaperStyle={{
          filter: 'blur(5px)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        dividerGradient="blue-green"
        style={{ minHeight: '350px' }}
        id="vision"
      >
        <div className="text-container text-white">
          <div className="subtitle">
            <NL>Onze visie</NL>
            <EN>Our vision</EN>
          </div>
          <h1>
            <NL>De mens versterkt door data en AI</NL>
            <EN>People empowered by data and AI</EN>
          </h1>
          <NL>
            <p>
              Wij geloven dat data science, machine learning en AI heel veel waarde kunnen leveren voor de mens. Door de
              mens te ondersteunen en niet per se te vervangen. Door tijd en energie vrij te maken, zodat de mens kan
              focussen op dat waar ze echt goed in zijn. Door duurzame oplossingen te bouwen en geen 'speeltjes'.
            </p>
          </NL>
          <EN>
            <p>
              We believe that data science, machine learning and artificial intelligence can be of real value to human
              beings. By supporting and not necessarily replacing them. By freeing up time and energy for humans to
              focus on that what they are really good at. By going for durability instead of a gimmick.
            </p>
          </EN>
          <div className="my-4" />
          {/*<h2 className="mb-3 h3">
             <span className="text-light-green">
               <NL>Hoe bereiken we dat?</NL>
               <EN>How do we achieve this?</EN>
             </span>
             {' '}
             <NL>Volgens deze waarden:</NL>
             <EN>Following these values:</EN>
           </h2>
           */}
          <div className="text-center mt-4">
            <Button
              color="white"
              outline
              to="#values"
            // className="stretched-link"
            >
              <NL>Zo bereiken we dat</NL>
              <EN>This is how we achieve that</EN>
            </Button>
          </div>

          <BackToTop />
        </div>
      </WallpaperSection>
      {/*</Section>*/}

      <Section id="values">
        <div className="text-container">
          <div className="subtitle">
            <NL>Onze waarden</NL>
            <EN>Our values</EN>
          </div>
          <h2 className="h1 text-teal">
            <NL>Door eerlijkheid, uitlegbaarheid en breder denken</NL>
            <EN>Through honesty, a human focus and a broad mind</EN>
          </h2>
          <NL>
            <p>
              Data en AI op een waardevolle manier inzetten, kan je volgens ons alleen doen als je eerlijk blijft, weet
              wat je technisch gezien aan het doen bent en de mens altijd centraal zet.
            </p>
            <p>Het volgende kun je ons dus aan houden:</p>
          </NL>
          <EN>
            <p>
              To implement data and AI in a valuable way, we find it very important to stay honest, know what you are
              doing technology wise and always keep the user in mind.
            </p>
            <p>So you can trust us to do the following:</p>
          </EN>
          <Section size="small" className="ps-5 pe-lg-5 pb-0">
            <h3 className="h2 text-aqua mb-3">
              <BigNumber>1</BigNumber>
              <NL>We zijn eerlijk over AI</NL>
              <EN>We are honest about AI</EN>
            </h3>
            <p>
              <NL>
                AI is een hype. Volledig terecht, want je kunt er prachtige dingen mee. Maar… dat geldt niet voor iedere
                organisatie of situatie. <strong>Soms is AI niet de juiste oplossing.</strong> Bij Landscape vertellen
                we altijd het volledige, eerlijke verhaal. We zetten AI alleen in als we in de toepassing geloven.
              </NL>
              <EN>
                AI is a hype. Of course, because it is capable of awesome things. But... this is not true for every
                organisation or situation. <strong>Sometimes AI is not the right solution.</strong> At Landscape we will
                stay honest with you. We will only implement AI if we truly believe in the value of a solution.
              </EN>
            </p>
            {/*
             <Button size="lg" color="green" outline to="/workshops">
               <NL>Leer van ons</NL>
               <EN>Learn from us</EN>
             </Button>
             */}
          </Section>

          <Section size="small" className="ps-5 pe-lg-5 pb-0">
            <h3 className="h2 text-blue mb-3">
              <BigNumber>2</BigNumber>
              <NL>We zetten de mens vóór de AI</NL>
              <EN>We put people before AI</EN>
            </h3>
            <NL>
              <p>
                AI is een tool (en geen doel). En net als iedere andere tool, kun je die op verschillende manieren
                inzetten. Wij voelen een sterke verantwoordelijkheid om AI op de juiste manier in te zetten. Dit zijn
                onze drie gouden regels:
              </p>
              <p>
                <ul>
                  <li>
                    De beslissingen van een model zijn <strong>uitlegbaar</strong>.
                  </li>
                  <li>
                    Er wordt rekening houden met de <strong>impact</strong> op de organisatie en gebruikers. Bij het
                    ontwikkelen van oplossingen staat de <strong>gebruiker centraal</strong>.
                  </li>
                  <li>
                    We gaan <strong>veilig</strong> met data om. We gebruiken verschillende methoden als{' '}
                    <strong>pseudonimiseren, anonimiseren en federated learning</strong>, waarbij we de letter en de
                    geest van de AVG volgen.
                  </li>
                </ul>
              </p>
            </NL>
            <EN>
              <p>
                AI is a tool. And as with any other tool, you can use it in multiple ways. We feel a strong
                responsibility to implement AI in the right way. These are our three golden rules:
              </p>
              <p>
                <ul>
                  <li>
                    The decisions of an AI model should be <strong>explainable</strong>.
                  </li>
                  <li>
                    The <strong>impact</strong> of AI solutions on the organisation and users should be taken into
                    account. The results must always <strong>meet the user's requirements</strong>.
                  </li>
                  <li>
                    We <strong>handle data safely</strong>. We use different methods like{' '}
                    <strong>pseudonymization, anonymisation and federated learning</strong> to always comply to the
                    GDPR.
                  </li>
                </ul>
              </p>
            </EN>
            <Button size="lg" color="blue" to="/portfolio">
              <NL>Bekijk ons portfolio</NL>
              <EN>Check out our portfolio</EN>
            </Button>
          </Section>

          <Section size="small" className="ps-5 pe-lg-5 pb-0">
            <h3 className="h2 text-dark-blue mb-3">
              <BigNumber>3</BigNumber>
              <NL>We kijken verder dan het algoritme</NL>
              <EN>We focus on more than algorithms</EN>
            </h3>
            <NL>
              <p>
                Bij het implementeren van AI gaat het nooit om alleen de techniek. Het ontwikkelen ervan is één, maar
                als het niet gebruikt wordt, is dat zonde van de investering. Daartoe is het belangrijk dat je rekening
                houdt met meerdere randvoorwaarden, van <strong>mens en kennis tot governance</strong>. Door jarenlange
                ervaring met een breed scala aan projecten in verschillende sectoren, weten we waar je op moet letten en
                hier helpen we je graag bij. Daarbij werken we graag nauw samen, omdat jij jouw business toch altijd het
                beste kent!
              </p>
            </NL>
            <EN>
              <p>
                Implementing AI is never about the technology alone. Developing it is one, but if it is not used, it is
                not worth the investment. That is why it is important to keep in mind multiple preconditions,{' '}
                <strong>from human capital to governance</strong>. Through years of experience with a great scala of
                projects in multiple sectors, we know what to take into account and gladly help you with this. Besides
                that we value a close collaboration, since of everyone you know your business best!
              </p>
            </EN>
            <Button size="lg" color="dark-blue" to={{ nl: '/diensten', en: '/services' }}>
              <NL>Meer over onze diensten</NL>
              <EN>More about our services</EN>
            </Button>
          </Section>
          <BackToTop />
        </div>
      </Section>

      <Section gradient="teal-blue" id="team">
        <div className="container text-white">
          <div className="subtitle">
            <NL>Ons team</NL>
            <EN>Our team</EN>
          </div>
          <h2 className="h1">
            <NL>De mensen achter de AI</NL>
            <EN>The people behind the AI</EN>
          </h2>
          <p>
            <NL>
              Niet alleen moeten data- en AI-oplossingen gericht zijn op mensen, ze worden ook nog eens gemaakt door
              mensen! Dit zijn die van ons!
            </NL>
            <EN>
              Not only should data and AI solutions focus on people, they are also made by people! These are ours!
            </EN>
          </p>
          <div className="row justify-content-center">
            <TeamPhoto photo={Erwin} name="Erwin" />
            <TeamPhoto photo={Iskander} name="Iskander" />
            <TeamPhoto photo={Jacob} name="Jacob" />
            <TeamPhoto photo={Merijn} name="Merijn" />
            <TeamPhoto photo={Roeland} name="Roeland" />
            <TeamPhoto photo={Rohiet} name="Rohiet" />
            <TeamPhoto photo={Swier} name="Swier" />
          </div>
          <div className="text-center mt-4">
            <Button color="white" outline to="#bios" className="stretched-link">
              <NL>Leer ons beter kennen!</NL>
              <EN>Get to know us better!</EN>
            </Button>
          </div>
        </div>
      </Section>
      <Section id="bios" size="small">
        <div className="text-container">
          <h2 className="h1 text-aqua">
            <NL>Wij zijn Landscape</NL>
            <EN>We are Landscape</EN>
          </h2>
          <p>
            <NL>
              Het Landscape-team bestaat uit een leuke club van slimme koppen met diverse achtergronden en expertises in
              o.a. informatica, business, wiskunde, psychologie, ontwerp en natuurkunde. Wat we allemaal gemeen hebben
              is een liefde en gevoel voor data en de drive dit met anderen te delen.
            </NL>
            <EN>
              The Landscape team consists of a fun club of bright minds with diverse backgrounds and expertises in
              fields like computer science, business, mathematics, psychology, design and physics. What they all have in
              common is their love for data and the drive to share its potential.
            </EN>
          </p>
          <h3 className="text-aqua">
            <NL>We stellen je graag aan iedereen voor:</NL>
            <EN>We'd like to introduce you to everyone:</EN>
          </h3>
        </div>
      </Section>
      <Section className="container" size="small">
        <div className="row justify-content-center">
          <Teammember
            name="Erwin Haas"
            photo={Erwin}
            jobTitle={
              <>
                <NL>Oprichter & CEO</NL>
                <EN>Founder & CEO</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van kaas</NL>
                <EN>Loves Cheese</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/erwinhaas/"
          >
            <NL>
              Erwin kan als geen ander mensen enthousiasmeren voor data, door helder uit te leggen hoe je het kan
              inzetten om problemen op te lossen. Hij is het meesterbrein achter Landscape, en je kan hem alles vragen
              over Machine Learning, Artificial Intelligence en Data Science in het algemeen. Erwin heeft een technische
              achtergrond en studeerde informatica aan de Universiteit Leiden (met een minor in Business Management).
            </NL>
            <EN>
              Like no other, Erwin excites people for data by clearly explaining how to apply it to real world problems.
              Being the mastermind behind Landscape, you can ask Erwin anything about Machine Learning, Artificial
              Intelligence and Data Science. Erwin has a strong technical background in Computer Science at Leiden
              University, with a minor in Business Management.
            </EN>
          </Teammember>
          <Teammember
            name="Rohiet Ramessar"
            photo={Rohiet}
            jobTitle={
              <>
                <NL>Head of Business Development</NL>
                <EN>Head of Business Development</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van autos</NL>
                <EN>Loves cars</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/erwinhaas/"
          >
            <NL>
              Rohiet zorgt er bij Landscape voor dat onze AI diensten en producten goed aansluiten bij wat organisaties
              écht nodig hebben. Hij houdt ervan om nauw met de rest van het team samen te werken aan toffe AI
              oplossingen, die precies op onze klanten zijn toegesneden. In de tijd dat hij niet druk is met business
              development voor Landscape kookt hij graag, en spendeert tijd met zijn lieve hondje of zijn auto.
            </NL>
            <EN>
              At Landscape, Rohiet ensures that our AI services and products are well aligned with what organisations
              really need. He enjoys working closely with the rest of the team on cool AI solutions that are tailored
              exactly to our clients. In the time he is not busy with business development for Landscape, he likes to
              cook, and spend time with his sweet dog or his sweet car.
            </EN>
          </Teammember>
        </div>
        <div className="row justify-content-center">
          <Teammember
            name="Iskander Westerkamp"
            photo={Iskander}
            jobTitle={
              <>
                <NL>Data Scientist</NL>
                <EN>Data Scientist</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van festivals</NL>
                <EN>Loves festivals</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/iskander-westerkamp/"
          >
            <NL>
              Iskander is de nieuwste aanwinst bij Landscape en staat bekend om zijn passie voor het oplossen van
              complexe problemen. Hij stelt graag kritische vragen en graaft diep om de kern van uitdagingen te
              doorgronden. Zijn creatieve 'out of the box' denken maakt hem een waardevolle aanvulling op het team. Met
              een achtergrond in biochemie en moleculaire genetica, en een master in bioinformatics en systems biology,
              brengt hij daarnaast een unieke combinatie van analytische vaardigheden en wetenschappelijke kennis mee.
              In zijn vrije tijd speelt Iskander graag Dungeons & Dragons, kookt hij graag en maakt hij tijd vrij om
              nieuwe plekken te ontdekken tijdens het reizen.
            </NL>
            <EN>
              Iskander is the newest addition to Landscape and is known for his passion for solving complex problems. He
              enjoys asking critical questions and digging deep to get to the heart of challenges. His creative, out of
              the box thinking makes him a valuable addition to the team. With a background in biochemistry and
              molecular genetics, and a master's degree in bioinformatics and systems biology, he also brings a unique
              combination of analytical skills and scientific knowledge. In his spare time, Iskander enjoys playing
              Dungeons & Dragons, cooking and making time to discover new places while travelling.
            </EN>
          </Teammember>
          <Teammember
            name="Jacob Boon"
            photo={Jacob}
            jobTitle={
              <>
                <NL>Data Engineer</NL>
                <EN>Data Engineer</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van programmeren</NL>
                <EN>Loves programming</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/jacobboon/"
          >
            <NL>
              Jacob is data scientist, JS-developer, grafisch ontwerper, de jongen die de financiën doet... Er is
              vrijwel geen onderdeel van Landscape waar hij zich niet inhoudelijk mee bezighoudt. Jacob heeft als
              achtergrond wiskunde (en een beetje scheikunde), wat hij studeerde in Leiden. Hij werkte eerder als
              grafisch en UI-ontwerper. Hij besteedt zijn vrije tijd graag aan knutselen in Adobe en in het echt, gamen,
              koken en Pathfinder.
            </NL>
            <EN>
              Jacob is data scientist, JS developer, graphical designer, finance guy... There's practically no field in
              Landscape of which Jacob has no in-depth knowledge of. Jacob has a background in Mathematics and a bit of
              Chemistry at Leiden University and has previously worked as graphic and interaction designer. He likes to
              fill his spare time with designing stuff on his Mac, arts & crafts, gaming, baking, and Dungeons &
              Dragons.
            </EN>
          </Teammember>
          <Teammember
            name="Merijn Mulder"
            photo={Merijn}
            jobTitle={
              <>
                <NL>Lead AI Business Strategy</NL>
                <EN>Lead AI Business Strategy</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van nasi</NL>
                <EN>Loves fried rice</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/merijn-mulder1/"
          >
            <NL>
              Merijns achtergrond in informatiekunde en focus op het duidelijk en begrijpelijk maken van complexe
              processen maken hem perfect voor zijn rol bij Landscape. Als Lead AI Business Strategy toont hij onze
              klanten de vereisten voor, en gevolgen van AI voor hun bedrijfsvoering en helpt hij de blauwdrukken van
              onze oplossingen ontwikkelen. Zijn werk is essentieel om te zorgen dat onze producten en oplossingen niet
              alleen technisch effectief, maar ook intuïtief en gebruikersvriendelijk zijn. Buiten zijn werk is Merijn
              een fervent gamer en triatleet, met een passie voor fietsen, zwemmen, en ontspannen met vrienden bij
              bordspellen of old school videogames. En met een goeie portie nasi kun je hem elke dag blij maken!
            </NL>
            <EN>
              Merijn's background in information science and his focus on making complex processes clear and
              understandable make him perfect for his role at Landscape. As Lead AI Business Strategy, he shows our
              customers the requirements for, and impacts of AI on their business and helps develop the blueprints of
              our solutions. His work is essential to ensuring that our products and solutions are not only technically
              effective, but also intuitive and user-friendly. Outside of work, Merijn is an avid gamer and triathlete,
              with a passion for cycling, swimming, and relaxing with friends at board games or old school video games.
              And a good serving of nasi can make him happy every day!
            </EN>
          </Teammember>
          <Teammember
            name="Roeland Oostdam"
            photo={Roeland}
            jobTitle={
              <>
                <NL>Data Scientist</NL>
                <EN>Data Scientist</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van koken</NL>
                <EN>Loves cooking</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/roeland-oostdam-952478165/"
          >
            <NL>
              Roeland eet het liefst data als ontbijt en heeft als data scientist een passie voor het oplossen van
              complexe problemen met behulp van data-analyse en machine learning. Met een achtergrond in Application
              Development en Artificial Intelligence, is hij in staat om data te vertalen naar waardevolle inzichten
              waar onze klanten wat aan hebben. Roeland is vastbesloten om door te blijven leren en te groeien in zijn
              rol als data scientist, om zodoende de best mogelijke resultaten voor ons bedrijf en klanten te bereiken.
              In zijn vrije tijd gaat hij het liefst richting de sportschool, squashbaan of boulderhal.
            </NL>
            <EN>
              Roeland preferably eats data for breakfast and as a data scientist he has a passion for solving complex
              problems using data analysis and machine learning. With a background in application development and
              artificial intelligence he is able to translate data into valuable insights that clients can profit from.
              Roeland is determined to keep on learning and developing in his role as data scientist to get the best
              possible results for all stakeholders. In his free time he preferably visits the gym, squash court or
              bouldering hall.
            </EN>
          </Teammember>
          <Teammember
            name="Swier Heeres"
            photo={Swier}
            jobTitle={
              <>
                <NL>Data Scientist</NL>
                <EN>Data Scientist</EN>
              </>
            }
            hobby={
              <>
                <NL>Houdt van gletsjers</NL>
                <EN>Loves Glaciers</EN>
              </>
            }
            linkedin="https://www.linkedin.com/in/swier-heeres/"
          >
            <NL>
              Swier is de data-cruncher van Landscape. Geef hem een grote bak data (het liefst netjes en gestructureerd)
              en hij zal niet rusten tot de computer er hard aan heeft lopen rekenen. Swier studeerde natuurkunde en
              klimaatwetenschappen in Leiden en in Utrecht. Hij houdt van nieuwe inzichten uit data halen, met de
              nieuwste en slimste algoritmes.
            </NL>
            <EN>
              Swier is Landscape's data cruncher a.k.a. mad scientist. Give him a large, preferably nicely structured,
              data set and he will not rest until the computer has successfully run his calculations. Swier has a
              background in Physics with an emphasis on Climate Science at Leiden and Utrecht University. He loves
              gaining new insights through clever analysis of data.
            </EN>
          </Teammember>
        </div>
        <div className="text-container">
          <BackToTop />
        </div>
      </Section>

      {/*<Section size="small" gradient="teal-blue">*/}
      <WallpaperSection
        //size="small"
        wallpaper={Landscapebord}
        wallpaperStyle={{
          filter: 'blur(5px)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        dividerGradient="blue-green"
        style={{ minHeight: '350px' }}
      >
        <div className="text-container text-white">
          <div className="subtitle">
            <NL>Onze oorsprong</NL>
            <EN>Our origin</EN>
          </div>
          <h2 className="h1">
            <NL>Van algemene data-hulp naar AI- & ML-specialisten</NL>
            <EN>From general data support to AI & ML specialists</EN>
          </h2>
          <p>
            <NL>
              <p>
                Landscape is opgericht in 2014 door Erwin Haas en Lucas van der Meer, toen masterstudenten aan de
                Universiteit Leiden. Ze wilden beiden altijd al ondernemen, en samen besloten ze hun gedeelde passie in
                te zetten om andere mensen te helpen met data.
              </p>
              <p>
                <strong>
                  Dit doet het huidige Landscape nog steeds, met een uitgebreide focus op AI. Machine Learning en AI
                  zijn voor ons niet nieuw, maar de interesse in en vraag ernaar wel!
                </strong>
              </p>
            </NL>
            <EN>
              <p>
                Landscape was founded in March 2014 by Erwin Haas and Lucas van der Meer, master students from Leiden
                University. Both had wanted to be entrepreneurs, and together they decided to embrace their shared
                passion, and use it to help others with data.
              </p>
              <p>
                <strong>
                  This is what the current Landscape still does, with a bigger focus on AI. Machine Learning and AI
                  aren't new to us, but the interest in and demand for it is!
                </strong>
              </p>
            </EN>
          </p>
        </div>
      </WallpaperSection>
      {/*</Section>*/}

      <Section id="attitude">
        <div className="text-container">
          <div />
          <h1 className="text-dark-blue">
            <NL>Onze houding</NL>
            <EN>Our attitude</EN>
          </h1>
          <p>
            <NL>Wat kun je van ons verwachten wanneer we samen aan een project werken?</NL>
            <EN>What can you expect from us when we do business together?</EN>
          </p>
          <div className="row">
            <div className="col-md-7 my-3 text-end">
              <h2 className="h3 text-blue">
                <NL>Doortastend en nieuwsgierig</NL>
                <EN>Thorough and curious</EN>
              </h2>
              <p>
                <NL>
                  Wij leveren de beste resultaten als we precies begrijpen wat jij nodig hebt, dus we doen ons uiterste
                  best om jouw problemen te doorgronden voor we ze aanpakken. We willen de onderliggende processen
                  begrijpen voor we oplossingen aandragen, en zullen vaak vragen... <strong>'Waarom?'</strong>
                </NL>
                <EN>
                  We deliver the best results when we know exactly what you need, so we strive to understand your
                  business problems before we tackle them. We want to understand underlying processes before offering
                  you solutions, and will frequently ask you <strong>'Why?'</strong>.
                </EN>
              </p>
            </div>

            <div className="col-md-7 my-3 ms-auto">
              <h2 className="h3 text-aqua">
                <NL>Brede visies en duurzame voorstellen</NL>
                <EN>Broad views and sustainable suggestions</EN>
              </h2>
              <p>
                <NL>
                  Wij gaan graag langdurige en betekenisvolle relaties aan. Bij elk project zullen we dan ook je
                  complete organisatie, de markt en toekomstige technieken in ons achterhoofd houden. We helpen je graag
                  aan duurzame oplossingen, die langdurig waarde leveren en met enthousiasme gebruikt worden.
                </NL>
                <EN>
                  We like to build long and meaningful relationships. So with every project we will always keep in mind
                  your entire organisation, the market and future techniques. We want to help you develop sustainable
                  solutions that are happily used.
                </EN>
              </p>
            </div>

            <div className="col-md-7 my-3 text-end">
              <h2 className="h3 text-teal">
                <NL>Open en nuchter</NL>
                <EN>Open and down to earth</EN>
              </h2>
              <p>
                <NL>
                  We communiceren eerlijk over wat realistische verwachtingen zijn en wat we redelijkerwijs kunnen
                  bereiken. We willen dat jij begrijpt hoe we waarde voor je creëren: we laten zien hoe onze modellen
                  werken en hoe die de data gebruiken. We nodigen je uit om betrokken te zijn en geven regelmatig
                  updates.
                </NL>
                <EN>
                  We communicate honestly about what realistic expectations are, and what we can feasibly accomplish. We
                  want you to understand how we create value for you: we show you how our models work and how they use
                  your data. We invite you to be involved, and keep you updated on a regular basis.
                </EN>
              </p>
            </div>

            <div className="col-md-7 my-3 ms-auto">
              <h2 className="h3 text-green">
                <NL>Eerlijk en onafhankelijk</NL>
                <EN>Honesty and independence</EN>
              </h2>
              <p>
                <NL>
                  Wij ontzenuwen hypes, in plaats van ze te proberen te verkopen. We geloven dat (AI-)oplossingen helder
                  en uitlegbaar moeten zijn, maar vooral ook <strong>realistisch</strong>. Ons advies zal misschien niet
                  altijd zijn wat je wil horen, maar we zullen zeggen waar het op staat en altijd eerlijk zijn. In jouw
                  voordeel.
                </NL>
                <EN>
                  We are in the business of demystifying hypes, not selling them. We believe solutions should be
                  explainable and clear, but most of all <strong>realistic</strong>. We won't always say what you want
                  to hear, but we'll be upfront and tell you the truth. To your advantage.
                </EN>
              </p>
            </div>

            <div className="col-md-7 my-3 text-end">
              <h2 className="h3 text-dark-green">
                <NL>Ontspannen en informeel</NL>
                <EN>Relaxed and personal</EN>
              </h2>
              <p>
                <NL>
                  Wij hechten aan aangename, persoonlijke communicatie en een ontspannen werkomgeving waar iedereen als
                  gelijke wordt behandeld. Zo hebben we de ruimte om creatieve oplossingen te verzinnen en discussies
                  effectief en onomwonden te voeren.&nbsp;{':)'}
                </NL>
                <EN>
                  We place great value in pleasant communication and a relaxed working environment where everyone is
                  treated as an equal. This gives us the opportunity to build creative solutions and discuss things
                  openly.&nbsp;{':)'}
                </EN>
              </p>
            </div>
          </div>
          <BackToTop />
        </div>
      </Section>
    </Page>
  )
}
